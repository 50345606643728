import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import Events from '../images/events.jpg';
import EventPage from '../images/event_page.jpg';
import SocialFeed from '../images/social_feed.jpg';
import News from '../images/news.jpg';
import Map from '../images/map.jpg';
import Exhibitors from '../images/exhibitors.jpg';
import ExhibitorPage from '../images/exhibitor_page.jpg';
import Schedule from '../images/schedule.jpg';
import AppFrame from '../images/app_frame.png';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const settings = {
  autoplay: true,
  pauseOnHover: true,
  centerMode: true,
  centerPadding: '150px',
  infinite: true,
  swipeToSlide: true,
  slidesToShow: 3,
  responsive: [
    {
      breakpoint: 1023,
      settings: {
        centerPadding: '10px',
        slidesToShow: 3
      }
    },
    {
      breakpoint: 768,
      settings: {
        centerPadding: '150px',
        slidesToShow: 1
      }
    },
    {
      breakpoint: 550,
      settings: {
        centerPadding: '80px',
        slidesToShow: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        centerPadding: '10px',
        slidesToShow: 1
      }
    }
  ]
};

class ImageCarousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      render: false
    };
  }

  componentDidMount() {
    this.setState({ render: true });
  }

  render() {
    const { id } = this.props;
    const { render } = this.state;

    if (!render) {
      return null;
    }

    return (
      <section id={id} className="py-12 mx-2 lg:py-32">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl lg:text-5xl font-semibold">App Screens</h2>
          <br />
          <br />
          <Slider {...settings}>
            <div className="px-4">
              <img src={Events} alt="Events" />
            </div>
            <div className="px-4">
              <img src={EventPage} alt="Event Page" />
            </div>
            <div className="px-4">
              <img src={SocialFeed} alt="Social Feed" />
            </div>
            <div className="px-4">
              <img src={News} alt="News" />
            </div>
            <div className="px-4">
              <img src={Map} alt="Map" />
            </div>
            <div className="px-4">
              <img src={Exhibitors} alt="Exhibitors" />
            </div>
            <div className="px-4">
              <img src={ExhibitorPage} alt="Exhibitor Page" />
            </div>
            <div className="px-4">
              <img src={Schedule} alt="Schedule" />
            </div>
          </Slider>
          <img className="phone-overlay mx-auto d-xs-none" src={AppFrame} alt="" />
        </div>
      </section>
    );
  }
}

ImageCarousel.propTypes = {
  id: PropTypes.string.isRequired
};

export default ImageCarousel;
