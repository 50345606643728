import React from "react";

const ExhibitorImage = () => (
  <svg
    id="bfec500d-0b80-48c2-bf1e-a3875eeeae28"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 888 765.55412"
  >
    <path
      d="M811.933,85.41388H780.36a23.44384,23.44384,0,0,1-21.70579,32.298H620.08555a23.44384,23.44384,0,0,1-21.70579-32.298H568.89048a49.34527,49.34527,0,0,0-49.34534,49.34521V760.16786a49.34529,49.34529,0,0,0,49.34534,49.34528H811.933a49.34529,49.34529,0,0,0,49.34534-49.34528V134.75909A49.34527,49.34527,0,0,0,811.933,85.41388Z"
      transform="translate(-156 -67.22294)"
      fill="#e6e6e6"
    />
    <path
      d="M809.26693,827.70438H567.38949a67.15329,67.15329,0,0,1-67.07715-67.07715V134.2996a67.15287,67.15287,0,0,1,67.07715-67.07666H809.26693A67.152,67.152,0,0,1,876.3431,134.2996v113.167h4.168v83.26563h-4.168v429.895A67.15246,67.15246,0,0,1,809.26693,827.70438ZM567.38949,69.22294a65.15059,65.15059,0,0,0-65.07715,65.07666V760.62723a65.15069,65.15069,0,0,0,65.07715,65.07715H809.26693a65.15048,65.15048,0,0,0,65.07617-65.07715v-431.895h4.168V249.46659h-4.168V134.2996a65.15038,65.15038,0,0,0-65.07617-65.07666Z"
      transform="translate(-156 -67.22294)"
      fill="#e6e6e6"
    />
    <path
      d="M436.16038,473.609l16.00073,24.78287,52.03362-.41521s21.87431-12.35292,26.27272,3.93455c1.50637,5.57812-21.39122,7.08774-23.54737,7.21838-5.39036.32658-59.15,9.65409-62.4691,4.87784s-20.777-21.4638-20.777-21.4638Z"
      transform="translate(-156 -67.22294)"
      fill="#ffb8b8"
    />
    <path
      d="M587.41732,184.89188a3.50426,3.50426,0,0,0-3.5,3.5v206a3.50425,3.50425,0,0,0,3.5,3.5h206a3.50424,3.50424,0,0,0,3.5-3.5v-206a3.50425,3.50425,0,0,0-3.5-3.5Z"
      transform="translate(-156 -67.22294)"
      fill="#fff"
    />
    <path
      d="M775.35319,427.37588H737.45965c-2.37-21.07945,2.859-36.79131,0-45.47218h37.89354A237.22411,237.22411,0,0,0,775.35319,427.37588Z"
      transform="translate(-156 -67.22294)"
      fill="#ff2410"
    />
    <rect x="374.91176" y="411.16895" width="319" height="144" fill="#ccc" />
    <path
      d="M756.41732,288.7819a65.82,65.82,0,1,1-65.82-65.82A65.78926,65.78926,0,0,1,756.41732,288.7819Z"
      transform="translate(-156 -67.22294)"
      fill="#ff2410"
    />
    <path
      d="M523.61557,470.64091a2.50294,2.50294,0,0,0-2.5,2.5v138a2.50263,2.50263,0,0,0,2.5,2.5h313a2.50232,2.50232,0,0,0,2.5-2.5v-138a2.50263,2.50263,0,0,0-2.5-2.5Z"
      transform="translate(-156 -67.22294)"
      fill="#fff"
    />
    <path
      d="M744.54818,500.58622a1.50164,1.50164,0,0,0-1.5,1.5v7.27246a1.50164,1.50164,0,0,0,1.5,1.5H787.3724a1.50164,1.50164,0,0,0,1.5-1.5v-7.27246a1.50164,1.50164,0,0,0-1.5-1.5Z"
      transform="translate(-156 -67.22294)"
      fill="#ff2410"
    />
    <path
      d="M582.39682,500.58622a1.50164,1.50164,0,0,0-1.5,1.5v7.27246a1.50164,1.50164,0,0,0,1.5,1.5H594.8724a1.50164,1.50164,0,0,0,1.5-1.5v-7.27246a1.50164,1.50164,0,0,0-1.5-1.5Z"
      transform="translate(-156 -67.22294)"
      fill="#ff2410"
    />
    <path
      d="M549.44662,500.58622a1.50164,1.50164,0,0,0-1.5,1.5v7.27246a1.50164,1.50164,0,0,0,1.5,1.5h12.4751a1.50164,1.50164,0,0,0,1.5-1.5v-7.27246a1.50164,1.50164,0,0,0-1.5-1.5Z"
      transform="translate(-156 -67.22294)"
      fill="#ff2410"
    />
    <path
      d="M616.2142,500.58622a1.50164,1.50164,0,0,0-1.5,1.5v7.27246a1.50164,1.50164,0,0,0,1.5,1.5H724.0726a1.50164,1.50164,0,0,0,1.5-1.5v-7.27246a1.50164,1.50164,0,0,0-1.5-1.5Z"
      transform="translate(-156 -67.22294)"
      fill="#ff2410"
    />
    <path
      d="M641.36068,573.42313a1.50164,1.50164,0,0,0-1.5,1.5v7.27344a1.50164,1.50164,0,0,0,1.5,1.5h12.47559a1.50164,1.50164,0,0,0,1.5-1.5v-7.27344a1.50164,1.50164,0,0,0-1.5-1.5Z"
      transform="translate(-156 -67.22294)"
      fill="#ff2410"
    />
    <path
      d="M608.41049,573.42313a1.50164,1.50164,0,0,0-1.5,1.5v7.27344a1.50164,1.50164,0,0,0,1.5,1.5h12.47558a1.50164,1.50164,0,0,0,1.5-1.5v-7.27344a1.50164,1.50164,0,0,0-1.5-1.5Z"
      transform="translate(-156 -67.22294)"
      fill="#ff2410"
    />
    <path
      d="M675.17807,573.42313a1.50164,1.50164,0,0,0-1.5,1.5v7.27344a1.50164,1.50164,0,0,0,1.5,1.5H783.03646a1.50164,1.50164,0,0,0,1.5-1.5v-7.27344a1.50164,1.50164,0,0,0-1.5-1.5Z"
      transform="translate(-156 -67.22294)"
      fill="#ff2410"
    />
    <path
      d="M610.14486,525.73173a1.50164,1.50164,0,0,0-1.5,1.5v7.27246a1.50164,1.50164,0,0,0,1.5,1.5h42.82422a1.50164,1.50164,0,0,0,1.5-1.5v-7.27246a1.50164,1.50164,0,0,0-1.5-1.5Z"
      transform="translate(-156 -67.22294)"
      fill="#ff2410"
    />
    <path
      d="M546.84506,525.73173a1.50164,1.50164,0,0,0-1.5,1.5v7.27246a1.50164,1.50164,0,0,0,1.5,1.5h42.82422a1.50164,1.50164,0,0,0,1.5-1.5v-7.27246a1.50164,1.50164,0,0,0-1.5-1.5Z"
      transform="translate(-156 -67.22294)"
      fill="#ff2410"
    />
    <path
      d="M800.91049,525.73173a1.50164,1.50164,0,0,0-1.5,1.5v7.27246a1.50164,1.50164,0,0,0,1.5,1.5h12.47558a1.50164,1.50164,0,0,0,1.5-1.5v-7.27246a1.50164,1.50164,0,0,0-1.5-1.5Z"
      transform="translate(-156 -67.22294)"
      fill="#ff2410"
    />
    <path
      d="M672.57748,525.73173a1.50164,1.50164,0,0,0-1.5,1.5v7.27246a1.50164,1.50164,0,0,0,1.5,1.5H780.4349a1.50164,1.50164,0,0,0,1.5-1.5v-7.27246a1.50164,1.50164,0,0,0-1.5-1.5Z"
      transform="translate(-156 -67.22294)"
      fill="#ff2410"
    />
    <path
      d="M707.26205,550.011a1.50164,1.50164,0,0,0-1.5,1.5v7.27246a1.50164,1.50164,0,0,0,1.5,1.5h42.82422a1.50164,1.50164,0,0,0,1.5-1.5V551.511a1.50164,1.50164,0,0,0-1.5-1.5Z"
      transform="translate(-156 -67.22294)"
      fill="#ff2410"
    />
    <path
      d="M770.56185,550.011a1.50165,1.50165,0,0,0-1.5,1.5v7.27246a1.50164,1.50164,0,0,0,1.5,1.5h42.82422a1.50164,1.50164,0,0,0,1.5-1.5V551.511a1.50164,1.50164,0,0,0-1.5-1.5Z"
      transform="translate(-156 -67.22294)"
      fill="#ff2410"
    />
    <path
      d="M546.84506,550.011a1.50164,1.50164,0,0,0-1.5,1.5v7.27246a1.50164,1.50164,0,0,0,1.5,1.5h12.47558a1.50164,1.50164,0,0,0,1.5-1.5V551.511a1.50164,1.50164,0,0,0-1.5-1.5Z"
      transform="translate(-156 -67.22294)"
      fill="#ff2410"
    />
    <path
      d="M579.79574,550.011a1.50164,1.50164,0,0,0-1.5,1.5v7.27246a1.50164,1.50164,0,0,0,1.5,1.5H687.65365a1.50164,1.50164,0,0,0,1.5-1.5V551.511a1.50164,1.50164,0,0,0-1.5-1.5Z"
      transform="translate(-156 -67.22294)"
      fill="#ff2410"
    />
    <path
      d="M697.31991,258.6481s12.88209-4.60075,21.16343,13.80223,21.16343,38.64627,21.16343,38.64627l-7.36119,1.8403s-1.8403-12.88209-6.44105-14.72239l1.8403,16.56269s-55.209,19.32313-80.053-1.8403l.92015-6.441s-3.6806.92015-3.6806,6.441l-2.76044-2.76045s2.76044-5.52089,11.04179-12.88209c5.43551-4.83156,7.30339-14.02356,7.94527-19.77055a23.92481,23.92481,0,0,1,5.50812-13.0287C672.10952,258.117,682.13745,251.338,697.31991,258.6481Z"
      transform="translate(-156 -67.22294)"
      fill="#2f2e41"
    />
    <circle cx="533.45503" cy="224.37368" r="23.81907" fill="#ffb8b8" />
    <path
      d="M717.91005,294.48652c-9.17138-6.028-16.59543-12.971-21.50643-21.23,0,0-16.83112,16.984-27.11681,17.83321s-.93506-20.38082-.93506-20.38082l18.70124-4.246,17.76619,1.69841,14.02593,8.492Z"
      transform="translate(-156 -67.22294)"
      fill="#2f2e41"
    />
    <rect x="524.41732" y="243.16895" width="18" height="18" fill="#ffb8b8" />
    <path
      d="M724.75735,345.05186a65.85044,65.85044,0,0,1-71.74-2.23,27.03833,27.03833,0,0,1,24.4-15.43h22A27.00555,27.00555,0,0,1,724.75735,345.05186Z"
      transform="translate(-156 -67.22294)"
      fill="#2f2e41"
    />
    <rect y="761.49655" width="888" height="2.24072" fill="#3f3d56" />
    <path
      d="M782.79026,650.4018h37.89351c2.36989-17.48761-2.859-33.81382,0-45.47221H782.79026Q772.08859,627.66569,782.79026,650.4018Z"
      transform="translate(-156 -67.22294)"
      fill="#ff6584"
    />
    <path
      d="M753.05468,642.13738H715.16116c1.42086-17.48446,6.002-33.81121,0-45.47221h37.89352Q763.75636,619.40129,753.05468,642.13738Z"
      transform="translate(-156 -67.22294)"
      fill="#ff2410"
    />
    <path
      d="M442.63075,383.29582s13.022-4.65071,21.3933,13.95215,21.39329,39.066,21.39329,39.066l-7.44114,1.86029s-1.86029-13.022-6.511-14.88229l1.86029,16.74258s-55.8086,19.533-80.92247-1.86029l.93014-6.511s-3.72057.93014-3.72057,6.511l-2.79043-2.79043s2.79043-5.58086,11.16172-13.022c5.49455-4.884,7.38271-14.17588,8.03157-19.98529a24.18451,24.18451,0,0,1,5.56795-13.1702C417.14654,382.759,427.28338,375.90635,442.63075,383.29582Z"
      transform="translate(-156 -67.22294)"
      fill="#2f2e41"
    />
    <path
      d="M367.28324,741.90347l-32.431,48.0874,30.19441,30.19441,3.91409-7.82818-6.70987-15.65636s1.11831-14.538,5.0324-18.45214S388.53117,750.85,388.53117,750.85Z"
      transform="translate(-156 -67.22294)"
      fill="#ffb8b8"
    />
    <path
      d="M408.66077,586.45816s-6.1495,9.73307,6.71108,51.66975c0,0-59.98732,101.24324-50.48167,107.95311s35.22875,21.55935,41.93862,17.0861,55.51093-156.0202,55.51093-156.0202Z"
      transform="translate(-156 -67.22294)"
      fill="#2f2e41"
    />
    <path
      d="M340.72334,781.324l-9.78522,9.78522c-5.03241,5.03241-8.38734,12.30143,1.11831,18.45214s42.49584,29.07611,53.679,21.80708-13.41974-13.97889-13.41974-13.97889-4.06819-15.084-6.22777-11.73567-2.15957,10.0582-2.15957,10.0582Z"
      transform="translate(-156 -67.22294)"
      fill="#2f2e41"
    />
    <path
      d="M458.9848,768.1838s17.893,32.99019,17.893,36.90428-1.11831,15.0972-1.11831,15.0972l34.1085,8.38734,11.74228-5.59156-3.91409-10.0648s-21.24792-7.269-25.72117-21.24792-9.50565-28.51695-9.50565-28.51695Z"
      transform="translate(-156 -67.22294)"
      fill="#ffb8b8"
    />
    <circle cx="282.29603" cy="349.25186" r="23.48454" fill="#ffb8b8" />
    <path
      d="M444.44675,436.6044l-11.21216,32.21577-24.57382-10.96785s16.77468-25.72116,16.77468-29.0761Z"
      transform="translate(-156 -67.22294)"
      fill="#ffb8b8"
    />
    <path
      d="M447.80168,551.23134H418.16642s-21.24792,32.431-8.94649,66.53954a176.81556,176.81556,0,0,1,5.62028,21.19c4.29247,19.89352,8.56624,46.84089,12.94592,71.603,6.15193,34.78225,12.51282,65.25288,19.428,65.8061,13.9789,1.11831,41.162.93171,41.162-4.65985s-18.2082-167.359-18.2082-167.359l-3.91409-6.70987L465.514,568.414a17.629,17.629,0,0,0-17.71231-17.18265Z"
      transform="translate(-156 -67.22294)"
      fill="#2f2e41"
    />
    <path
      d="M477.15736,804.8085s-6.43029,10.90354-4.75282,18.73172,4.47324,6.70987,16.77467,7.82818,54.23811,1.11831,54.23811-6.70987-32.7142-17.99424-32.8522-14.86825-1.2563,9.27669,2.09863,10.395.27958.27958.27958.27958Z"
      transform="translate(-156 -67.22294)"
      fill="#2f2e41"
    />
    <path
      d="M420.38694,566.81535c4.37277,0,13.77767-11.23226,18.69977-6.30988,3.57991,3.5799,6.61759,7.01866,9.29761,10.05251,7.6933,8.70924,14.41893,16.691,19.09266,10.96415.70646-.86565-1.66859,1.13361.31193-.21377a4.79174,4.79174,0,0,0,1.93136-5.19976c-6.78141-21.24571-11.00436-41.59925-5.61185-58.59986a13.7932,13.7932,0,0,0,.567-4.16q.00068-.3087.02871-.61829c.285-3.13734.21351-14.37369-12.66073-27.24793-11.7663-11.76629-14.02367-18.99655-14.32127-22.2592-.06989-.75983-19.03723-20.43868-19.03723-20.43868a4.76866,4.76866,0,0,0-5.71087,1.60294l-6.07549,8.35381a79.90455,79.90455,0,0,0-8.33208,42.31968c1.64487,18.95539,4.50058,56.44153,6.846,69.96334a4.93994,4.93994,0,0,0,1.82923,3.11564,4.70958,4.70958,0,0,0,4.82126.57069A20.3058,20.3058,0,0,1,420.38694,566.81535Z"
      transform="translate(-156 -67.22294)"
      fill="#2f2e41"
    />
    <polygon
      points="271.168 645.108 260.148 571.682 269.111 650.948 271.168 645.108"
      opacity="0.2"
    />
    <path
      d="M441.16038,494.609l16.00073,24.78287,52.03362-.41521s21.87431-12.35292,26.27272,3.93455c1.50637,5.57812-21.39122,7.08774-23.54737,7.21838-5.39036.32658-59.15,9.65409-62.4691,4.87784s-20.777-21.4638-20.777-21.4638Z"
      transform="translate(-156 -67.22294)"
      fill="#ffb8b8"
    />
    <path
      d="M417.23068,477.22324s9.08538-5.92343,21.55883,4.6097,14.96814,19.95752,14.96814,19.95752l-29.3819,17.46283S395.67185,488.69043,417.23068,477.22324Z"
      transform="translate(-156 -67.22294)"
      fill="#2f2e41"
    />
    <path
      d="M463.44453,417.52351c-9.271-6.09346-16.77568-13.11187-21.74-21.46061,0,0-17.01394,17.16849-27.41134,18.02691s-.94522-20.60219-.94522-20.60219l18.90437-4.29212,17.95915,1.71685,14.17828,8.58424Z"
      transform="translate(-156 -67.22294)"
      fill="#2f2e41"
    />
    <ellipse cx="258.91732" cy="348.66895" rx="2.5" ry="4.5" fill="#ffb8b8" />
  </svg>
);

export default ExhibitorImage;
