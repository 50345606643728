import React from 'react';
import Footer from './Footer';
import Header from './Header';

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hideHeader: true
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateWindowDimensions);
    this.updateWindowDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    const { hideHeader } = this.state;
    const willHideHeader = window.innerWidth < 1024;
    if (willHideHeader !== hideHeader) {
      this.setState({ hideHeader: willHideHeader });
    }
  }

  render() {
    const { children } = this.props;
    const { hideHeader } = this.state;
    return (
      <>
        <Header hide={hideHeader} />
        <main className="text-gray-900">{children}</main>
        <Footer />
      </>
    );
  }
}

export default Layout;
