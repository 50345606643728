import React from 'react';
import PropTypes from 'prop-types';
import '../css/styles.css';

const sizes = {
  default: `py-3 px-8`,
  lg: `py-4 px-12`,
  xl: `py-5 px-16 text-lg`
};

const Button = ({ children, className, size, type, onClick }) => {
  return (
    // eslint-disable-next-line react/button-has-type
    <button
      type={type}
      className={`
        ${sizes[size] || sizes.default}
        ${className}
        rounded
      `}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  onClick: PropTypes.func
};

Button.defaultProps = {
  className: '',
  size: null,
  type: 'button',
  onClick: null
};

export default Button;
