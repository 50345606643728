import React from "react";

const AttendeeImage = () => (
  <svg
    id="b18c4a64-54bc-43ba-8216-386ddc8e43d1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 903.34511 605.22204"
  >
    <path
      d="M757.2688,168.337s2.11192,2.42027-1.50669,7.1032-.69665,14.84439,6.17815,15.10326,10.20866,3.13823,8.55123,6.93117-3.64908,12.80554,3.83784,11.43517,9.85311,1.61024,8.52763,3.90107,3.33845-4.55578,3.33845-4.55578,4.17206,6.2155,9.29278,1.86452,7.1566-43.79164-3.28274-44.46012S757.2688,168.337,757.2688,168.337Z"
      transform="translate(-148.32744 -147.38898)"
      fill="#2f2e41"
    />
    <polygon
      points="661.864 208.51 664.243 225.162 674.948 225.162 671.38 206.131 661.864 208.51"
      fill="#9f616a"
    />
    <path
      d="M824.46532,362.44094s14.27365,13.08418,10.70523,14.27365S818.518,392.17771,816.139,393.36718c-2.12779,1.06389-24.97889,8.3263-21.41048,0S806.62325,380.283,806.62325,380.283l2.37894-14.27365Z"
      transform="translate(-148.32744 -147.38898)"
      fill="#f2f2f2"
    />
    <path
      d="M824.46532,362.44094s14.27365,13.08418,10.70523,14.27365S818.518,392.17771,816.139,393.36718c-2.12779,1.06389-24.97889,8.3263-21.41048,0S806.62325,380.283,806.62325,380.283l2.37894-14.27365Z"
      transform="translate(-148.32744 -147.38898)"
      opacity="0.1"
    />
    <polygon
      points="676.138 196.02 678.517 212.673 689.222 212.673 685.654 193.641 676.138 196.02"
      fill="#9f616a"
    />
    <path
      d="M779.73372,299.205l15.58956-51.548s-26.16836-23.78942-8.3263-30.92624,22.6,28.5473,22.6,28.5473l-5.94735,61.85248H779.86016Z"
      transform="translate(-148.32744 -147.38898)"
      fill="#ffb8b8"
    />
    <path
      d="M816.139,261.33592s-30.74222,39.42989-26.07635,45.28856,12.99217,41.54281,12.99217,41.54281-1.18947,14.27365,3.56841,14.27365,16.6526-2.37894,16.6526-5.94736-10.70524-48.7683-10.70524-48.7683l30.92624-40.442Z"
      transform="translate(-148.32744 -147.38898)"
      fill="#ff2410"
    />
    <path
      d="M818.518,263.71486l30.92624-16.65259s21.41048,10.70523,15.46312,20.221-33.30518,34.49465-33.30518,34.49465,8.3263,40.442,7.13683,41.63148-14.27365,5.94736-17.84207,4.75789-22.59994-47.57884-17.84206-49.95778,20.221-27.35783,20.221-27.35783Z"
      transform="translate(-148.32744 -147.38898)"
      fill="#ff2410"
    />
    <path
      d="M734.06553,700.25066S755.476,713.33484,757.855,713.33484s34.49465,8.32629,20.221,14.27365-68.98931-2.37895-67.79983-5.94736,0-24.97889,2.37894-24.97889S734.06553,700.25066,734.06553,700.25066Z"
      transform="translate(-148.32744 -147.38898)"
      fill="#2f2e41"
    />
    <path
      d="M757.855,716.90325s21.41047,13.08418,23.78942,13.08418,34.49465,8.32629,20.221,14.27365-68.98931-2.37894-67.79984-5.94736,0-24.97888,2.37894-24.97888S757.855,716.90325,757.855,716.90325Z"
      transform="translate(-148.32744 -147.38898)"
      fill="#2f2e41"
    />
    <path
      d="M735.255,464.73543s3.56842,17.84206,9.51577,30.92624-1.18947,74.93666-4.75788,84.45243-38.06307,122.5155-27.35783,123.705,32.11571,0,32.11571,0l34.49465-108.24185-5.94735-145.11544Z"
      transform="translate(-148.32744 -147.38898)"
      fill="#575a88"
    />
    <path
      d="M735.255,464.73543s3.56842,17.84206,9.51577,30.92624-1.18947,74.93666-4.75788,84.45243-38.06307,122.5155-27.35783,123.705,32.11571,0,32.11571,0l34.49465-108.24185-5.94735-145.11544Z"
      transform="translate(-148.32744 -147.38898)"
      opacity="0.1"
    />
    <path
      d="M742.39183,465.9249s4.75788,32.11571,15.46312,39.25254,7.13682,14.27365,7.13682,14.27365L760.23389,582.493s-46.38936,114.1892-32.11571,128.46285,27.35783,8.3263,33.30518,7.13683,16.65259-26.16836,10.70524-33.30518,11.89471-49.95778,16.65259-57.0946,0-16.6526,5.94735-19.03154,19.03154-17.84206,19.03154-28.5473,16.65259-124.89444,5.94735-132.03126-79.325,14.72982-79.325,14.72982Z"
      transform="translate(-148.32744 -147.38898)"
      fill="#575a88"
    />
    <circle cx="571.46444" cy="125.84165" r="26.16836" fill="#ffb8b8" />
    <circle cx="630.93798" cy="30.68398" r="21.41048" fill="#9f616a" />
    <path
      d="M737.63394,273.23062S751.90759,299.399,759.04442,299.399s-28.5473,27.35783-28.5473,27.35783-11.89471-34.49465-14.27365-35.68412S737.63394,273.23062,737.63394,273.23062Z"
      transform="translate(-148.32744 -147.38898)"
      fill="#ffb8b8"
    />
    <path
      d="M742.39183,297.02s-30.92624,10.70524-29.73677,14.27365-8.3263,11.89471-8.3263,15.46312-2.37894,35.68413,11.89471,61.85249,36.8736,45.19989,26.16836,57.0946-27.35783,32.11571-9.51577,32.11571,46.38936-26.16836,72.55772-16.65259,20.221-26.16836,20.221-26.16836,3.56841-27.35783-3.56842-35.68413-32.11571-67.79983-32.11571-74.93666-23.78942-34.49465-29.73677-34.49465S742.39183,297.02,742.39183,297.02Z"
      transform="translate(-148.32744 -147.38898)"
      fill="#8985a8"
    />
    <path
      d="M779.26542,191.15714"
      transform="translate(-148.32744 -147.38898)"
      fill="none"
      stroke="#2f2e41"
      strokeMiterlimit="10"
    />
    <path
      d="M785.21278,191.15714s9.68589,18.41584,10.19556,21.69736,17.16227-21.69736,17.16227-21.69736L793.53907,178.073Z"
      transform="translate(-148.32744 -147.38898)"
      fill="#9f616a"
    />
    <path
      d="M788.78119,205.43079l20.221-21.41048,23.78942,10.70524s22.18674,48.7683,23.58282,52.33672-35.47753,20.221-39.04594,20.221-28.5473-36.8736-28.5473-36.8736S782.83384,212.56761,788.78119,205.43079Z"
      transform="translate(-148.32744 -147.38898)"
      fill="#f2f2f2"
    />
    <polygon
      points="630.938 86.589 630.938 108 645.212 108 640.517 86.589 630.938 86.589"
      fill="#9f616a"
    />
    <path
      d="M787.40937,250.03594a8.87141,8.87141,0,0,0-8.37933,5.63254c-.92981,2.51473-.61424,5.15766,3.8038,6.26217,9.51576,2.37894,10.70523,0,10.70523,0s16.51883-2.37894,13.0173-7.13682-13.0173-4.75789-13.0173-4.75789Z"
      transform="translate(-148.32744 -147.38898)"
      fill="#9f616a"
    />
    <path
      d="M838.739,352.92517s14.27365,13.08418,10.70523,14.27365-16.65259,15.46312-19.03153,16.65259c-2.12779,1.0639-24.97889,8.3263-21.41048,0s11.89471-13.08418,11.89471-13.08418l2.379-14.27365Z"
      transform="translate(-148.32744 -147.38898)"
      fill="#f2f2f2"
    />
    <path
      d="M710.46817,253.08746c1.63718-.95935,3.57076-2.15416,3.61752-4.05113a3.58612,3.58612,0,0,0-2.35145-3.15015,10.52834,10.52834,0,0,0-4.11048-.55393,5.3501,5.3501,0,0,0-1.58915.15752c-1.3549.4179-2.13544,1.79359-3.20262,2.72715-1.54961,1.3556-3.684,1.75431-5.677,2.27095a37.16486,37.16486,0,0,0-9.51449,3.943,4.46217,4.46217,0,0,0-1.50585,1.23882,6.07547,6.07547,0,0,0-.605,3.07885c-.26851,4.03572-2.6881,7.54864-4.49439,11.16754s-2.993,8.12706-.87288,11.57153a39.39718,39.39718,0,0,1,2.3526,3.33963c1.50253,3.13092-.37279,6.986.71282,10.28475a8.99455,8.99455,0,0,0,2.27742,3.41467,18.09635,18.09635,0,0,0,7.49483,4.53791c2.703.83229,6.05663,1.38694,7.11528,4.0096.45414,1.12507.36146,2.45057,1.00216,3.48087.79369,1.27632,2.43,1.68862,3.9081,1.96081a16.70114,16.70114,0,0,0,5.063.36641,6.28231,6.28231,0,0,0,4.36732-2.33778,12.771,12.771,0,0,1,1.76835-2.60783c1.72226-1.43941,4.33832-.30248,6.55025-.68389a4.79085,4.79085,0,0,0,3.754-3.52358,6.6705,6.6705,0,0,1,.762-2.69281c1.37364-1.7578,4.988-.51687,5.895-2.55506.58931-1.3244-.64362-2.72359-1.83578-3.54826-1.94388-1.34467-4.11694-2.37892-5.86036-3.97495s-3.03332-3.98395-2.48746-6.28371c.8186-3.44883,5.32145-5.34781,5.47064-8.88932.08546-2.02883-1.37247-3.80927-3.00464-5.01736s-3.52344-2.0761-4.98244-3.48847c-1.06648-1.03239-1.87065-2.328-3.03-3.25488-2.09654-1.67627-4.96524-1.87957-7.6355-2.15365-1.43229-.147-7.14553-.80866-6.92077-2.99039C703.0858,257.06833,709.01437,253.93936,710.46817,253.08746Z"
      transform="translate(-148.32744 -147.38898)"
      fill="#2f2e41"
    />
    <path
      d="M788.78119,204.24132S775.697,208.9992,775.697,214.94655s-5.94735,24.97889,0,24.97889,17.84206-4.56588,17.84206-4.56588Z"
      transform="translate(-148.32744 -147.38898)"
      fill="#f2f2f2"
    />
    <path
      d="M785.21278,289.88322s34.49465-11.89471,35.68412-11.89471,17.84207-9.51577,16.6526-13.08418l-4.75789-29.73677S813.76008,195.915,826.84426,195.915s20.221,30.92624,20.221,30.92624,28.5473,53.52619,13.08418,65.4209-79.69455,33.30518-83.263,29.73677S785.21278,289.88322,785.21278,289.88322Z"
      transform="translate(-148.32744 -147.38898)"
      fill="#ffb8b8"
    />
    <path
      d="M818.518,212.56761l8.02893,7.73723L816.139,230.40967s-14.27365-5.94735-13.08418-2.37894,8.3263,14.27365,13.08418,14.27365,29.52053-15.46312,29.62865-20.221-15.355-21.41048-18.92341-22.6S818.518,212.56761,818.518,212.56761Z"
      transform="translate(-148.32744 -147.38898)"
      fill="#9f616a"
    />
    <path
      d="M803.42089,204.27639s17.476,15.42805,19.855,15.42805,6.70538-23.18016,6.70538-23.18016-14.10046-9.18744-19.32395-6.68256S803.42089,204.27639,803.42089,204.27639Z"
      transform="translate(-148.32744 -147.38898)"
      fill="#f2f2f2"
    />
    <path
      d="M742.39183,304.15687s0-16.6526,35.68412-16.6526h15.46312l-7.13682,39.25254-23.78942,7.13683Z"
      transform="translate(-148.32744 -147.38898)"
      fill="#8985a8"
    />
    <path
      d="M820.33929,169.39528A92.36931,92.36931,0,0,1,809.876,168.75c-2.72736-6.59088-6.201-12.12116-9.95863-14.183-2.66688-2.38631-6.7801-4.12049-10.00742-5.49161-7.92548-3.367-21.11881-1.26691-21.89009,2.56237-15.59523,7.45178-11.65433,20.53836-11.65433,20.53836,2.42317,5.12218,7.85947,2.85206,12.47142.53242,1.17774,3.7131,3.95955,6.8642,7.94217,7.01416,7.61589.28677,11.30914,3.47651,9.473,7.67836s-4.04246,14.186,4.25153,12.66787,10.91531,1.78383,9.44692,4.32162,3.69835-5.04691,3.69835-5.04691,4.62179,6.88555,10.29452,2.06552a4.30532,4.30532,0,0,0,1.18182-2.02451c2.638,1.13535,4.72739,1.56631,5.9138,1.0355,6.79483-3.04016,2.57493-10.17906,2.57493-10.17906s9.07116-1.39157,6.14827-1.16088-4.77195-3.5581.17384-10.387S824.92214,169.55026,820.33929,169.39528Z"
      transform="translate(-148.32744 -147.38898)"
      fill="#2f2e41"
    />
    <rect
      x="769.15492"
      y="163.20457"
      width="3.56841"
      height="10.70524"
      transform="translate(-55.66122 -382.19828) rotate(18.40679)"
      fill="#ff2410"
    />
    <path
      d="M371.35407,254.98589s-1.41976,4.73256,6.62559,8.99187,10.41164,20.35.94652,25.08259-12.30467,10.88489-7.57211,15.1442,13.25118,15.61746,1.893,18.457-12.77792,8.51861-9.46513,10.8849-7.5721-4.25931-7.5721-4.25931-1.893,11.35815-11.83141,8.51861S306.518,281.015,320.71564,273.44289,371.35407,254.98589,371.35407,254.98589Z"
      transform="translate(-148.32744 -147.38898)"
      fill="#2f2e41"
    />
    <polygon
      points="186.357 508.814 189.434 537.531 176.101 545.736 163.794 543.685 169.947 508.814 186.357 508.814"
      fill="#9f616a"
    />
    <circle cx="196.61325" cy="135.49002" r="28.71724" fill="#9f616a" />
    <path
      d="M354.68405,296.72481s2.564,43.58867,5.64089,43.58867-35.89656-2.05123-35.89656-2.05123,16.40986-28.71724,8.20493-37.94778S354.68405,296.72481,354.68405,296.72481Z"
      transform="translate(-148.32744 -147.38898)"
      fill="#9f616a"
    />
    <polygon
      points="163.794 379.587 177.127 430.867 166.87 518.045 190.46 515.993 199.69 379.587 163.794 379.587"
      fill="#2f2e41"
    />
    <polygon
      points="163.794 379.587 177.127 430.867 166.87 518.045 190.46 515.993 199.69 379.587 163.794 379.587"
      opacity="0.2"
    />
    <path
      d="M332.63331,679.7923s1.02561-6.15369,11.28177,0,53.332,25.6404,30.76848,29.74286-68.71626-5.12808-68.71626-8.20493-1.02562-22.56354,3.07685-20.51231S326.47961,696.20215,332.63331,679.7923Z"
      transform="translate(-148.32744 -147.38898)"
      fill="#2f2e41"
    />
    <path
      d="M319.3003,672.613s-16.40985,25.6404-17.43546,32.81971S285.455,689.02284,285.455,689.02284l14.35862-26.666Z"
      transform="translate(-148.32744 -147.38898)"
      fill="#9f616a"
    />
    <path
      d="M373.65794,531.078l-18.46108,61.537s-32.81971,87.17734-34.87094,86.15173-26.666-7.17931-27.69163-10.25616,30.76848-86.15173,30.76848-86.15173l4.10246-53.332Z"
      transform="translate(-148.32744 -147.38898)"
      fill="#2f2e41"
    />
    <path
      d="M282.37814,684.92038a13.4641,13.4641,0,0,1,13.333,8.20493c4.10246,9.23054,9.23054,10.25615,10.25616,11.28177s-3.07685,22.56355,0,24.61478S312.121,752.611,301.86484,752.611s-26.666-11.28177-25.6404-13.333,1.02562-16.40986-2.05123-22.56355-8.20493-16.40985,0-22.56355S282.37814,684.92038,282.37814,684.92038Z"
      transform="translate(-148.32744 -147.38898)"
      fill="#2f2e41"
    />
    <path
      d="M379.81163,435.69575l2.05124,18.46108s15.38423,15.38424,20.51231,33.84532,47.17833,49.22956,34.87094,53.332-26.666,6.1537-62.56256,0-92.30542,7.17931-92.30542-26.666c0,0-7.17931-9.23054-12.30739,4.10247a268.06752,268.06752,0,0,1-12.30739,26.666s-12.30739-21.53794-8.20493-30.76848,0-8.20492,5.12808-18.46108,30.76847-48.20394,41.02463-50.25517,29.74286-28.71725,28.71724-31.79409S379.81163,435.69575,379.81163,435.69575Z"
      transform="translate(-148.32744 -147.38898)"
      fill="#ff2410"
    />
    <path
      d="M324.42838,320.82678s42.05025,9.23054,44.10148,15.38424c1.15382,3.46145,6.52628,9.84351,11.00634,14.76526a57.6445,57.6445,0,0,1,14.47569,29.37279c1.06956,6.54316-3.42717,12.42663-5.48252,19.96293-6.1537,22.56355-10.769,16.92266-4.61527,33.33252s-30.76848,22.56354-45.1271,15.38423-30.25566-49.74236-30.25566-54.87044-13.84581-63.07537-2.564-67.17783S324.42838,320.82678,324.42838,320.82678Z"
      transform="translate(-148.32744 -147.38898)"
      fill="#575a88"
    />
    <path
      d="M382.88848,370.05634,395.19587,424.414l40.51182,17.94828s30.76848-7.17931,31.79409,4.10246c.66959,7.36545-34.87093,10.25616-34.87093,10.25616l-58.97291-23.07635-13.333-50.25518Z"
      transform="translate(-148.32744 -147.38898)"
      fill="#9f616a"
    />
    <path
      d="M368.52986,349.544s25.6404,15.38424,19.4867,23.58917-21.53793,21.53793-25.64039,21.53793-7.17931-24.61478-7.17931-31.79409S368.52986,349.544,368.52986,349.544Z"
      transform="translate(-148.32744 -147.38898)"
      fill="#575a88"
    />
    <path
      d="M342.87466,245.58824s-1.57282,5.24273,7.33983,9.96119,11.534,22.54376,1.04854,27.78649-13.6311,12.05829-8.38837,16.77675,14.67966,17.301,2.09709,20.44666-14.15538,9.43693-10.48546,12.05829-8.38838-4.71846-8.38838-4.71846-2.09709,12.58256-13.10683,9.43692-21.99477-60.32605-9.722-75.05334C314.68053,248.589,342.87466,245.58824,342.87466,245.58824Z"
      transform="translate(-148.32744 -147.38898)"
      fill="#2f2e41"
    />
    <path
      d="M346.22365,240.1918c-.0024-7.68088-22.98181-2.16181-32.83416,8.5624-6.82252,7.42637-17.18522,18.59911-8.50159,26.47477s-14.88446,18.19984-21.20523,21.33S264.882,309.2614,276.15044,315.683s11.08679,12.904,6.846,14.43881,9.34991-2.28206,9.34991-2.28206-1.37306,12.682,10.07708,12.6213,44.2997-62.98738,36.45221-80.47823C331.57888,243.71946,346.22365,240.1918,346.22365,240.1918Z"
      transform="translate(-148.32744 -147.38898)"
      fill="#2f2e41"
    />
    <path
      d="M341.24624,238.50857c30.72949-1.85043,33.81356,21.28082,33.81356,21.28082-.21244,13.38608-21.47011,6.86364-27.37427,8.85916-7.123-5.12408-20.85317-14.81943-30.67256-21.7385A46.44159,46.44159,0,0,1,341.24624,238.50857Z"
      transform="translate(-148.32744 -147.38898)"
      fill="#2f2e41"
    />
    <rect
      x="422.25126"
      y="359.28264"
      width="9.82683"
      height="229.29279"
      fill="#381e1d"
    />
    <path
      d="M570.57871,506.67162s16.70561-20.96392,30.46318,6.22366c0,0-6.55122,12.11976-8.51659,6.22366s-6.67319-14.71075-12.18075-9.48452"
      transform="translate(-148.32744 -147.38898)"
      fill="#381e1d"
    />
    <path
      d="M569.10173,466.6229s24.71727,24.33224,12.54541,44.88477a4.86022,4.86022,0,0,1-7.60344.97286c-4.19587-4.14989-10.83111-14.45791-18.98151-39.962Z"
      transform="translate(-148.32744 -147.38898)"
      fill="#fda57d"
    />
    <path
      d="M551.74394,338.469s25.54977,7.86147,20.63635,38.65222-2.948,52.40978-2.948,52.40978l6.55123,39.30733-11.79221,16.0505S543.88247,464.58,537.00369,443.94366,530.78,341.74458,551.74394,338.469Z"
      transform="translate(-148.32744 -147.38898)"
      opacity="0.1"
    />
    <path
      d="M551.41638,338.14141s25.54977,7.86147,20.63635,38.65222-2.948,52.40978-2.948,52.40978l6.55122,39.30733-11.7922,16.0505s-20.30879-20.30879-27.18757-40.94514S530.45247,341.417,551.41638,338.14141Z"
      transform="translate(-148.32744 -147.38898)"
      fill="#ff2410"
    />
    <path
      d="M521.45575,688.76983l-4.18846,31.36643s10.752,4.40152,13.97395,3.40532,8.86948,3.05209,8.86948,3.05209,24.02624,7.59026,28.74426.76991-5.984-8.90621-14.66689-14.0474-12.35446-24.986-12.35446-24.986Z"
      transform="translate(-148.32744 -147.38898)"
    />
    <path
      d="M567.3031,467.36428V583.32092L545.02894,694.03658h-23.5844l3.60317-106.1298s-24.56709-85.821-13.75757-117.922Z"
      transform="translate(-148.32744 -147.38898)"
      fill="#333"
    />
    <path
      d="M549.13333,481.994s34.34194,4.86137,36.77976,28.62306a4.86022,4.86022,0,0,1-5.534,5.30408c-5.84123-.84083-17.30594-5.18158-39.02623-20.83779Z"
      transform="translate(-148.32744 -147.38898)"
      fill="#fda57d"
    />
    <path
      d="M553.38175,332.90043s-5.56854,9.49928-3.27561,25.87733c0,0-32.9199-2.78427-31.93721-21.78281,0,0,16.05049-3.93074,19.81744-18.50721Z"
      transform="translate(-148.32744 -147.38898)"
      fill="#fda57d"
    />
    <path
      d="M498.52647,695.64861,494.338,727.015s10.752,4.40153,13.974,3.40532,8.86948,3.05209,8.86948,3.05209,24.02624,7.59026,28.74426.76991-5.984-8.90621-14.66689-14.0474-12.35446-24.986-12.35446-24.986Z"
      transform="translate(-148.32744 -147.38898)"
    />
    <path
      d="M542.57223,476.37221V592.32885L520.29807,703.04452h-23.5844l3.60317-106.12981s-24.56708-85.821-13.75756-117.922Z"
      transform="translate(-148.32744 -147.38898)"
      opacity="0.1"
    />
    <path
      d="M541.58955,476.37221V592.32885L519.31539,703.04452H495.731l3.60317-106.12981s-24.56708-85.821-13.75757-117.922Z"
      transform="translate(-148.32744 -147.38898)"
      fill="#333"
    />
    <path
      d="M529.79735,338.79653s-20.63636-13.43-34.39392,26.53246-8.189,113.66371-8.189,113.66371,58.63344,18.99855,79.59735-7.53391V431.8239s11.13708-45.20344-4.58585-84.51078C562.2259,347.31312,548.46833,352.88166,529.79735,338.79653Z"
      transform="translate(-148.32744 -147.38898)"
      opacity="0.1"
    />
    <path
      d="M529.14222,339.77922s-20.63635-13.43-34.39392,26.53245-8.189,113.66371-8.189,113.66371,58.63344,18.99855,79.59735-7.5339v-39.6349s11.13708-45.20344-4.58585-84.51077C561.57078,348.29581,547.81321,353.86435,529.14222,339.77922Z"
      transform="translate(-148.32744 -147.38898)"
      opacity="0.1"
    />
    <path
      d="M529.14222,338.79653s-20.63635-13.43-34.39392,26.53246-8.189,113.66371-8.189,113.66371,58.63344,18.99855,79.59735-7.53391V431.8239s11.13708-45.20344-4.58585-84.51078C561.57078,347.31312,547.81321,352.88166,529.14222,338.79653Z"
      transform="translate(-148.32744 -147.38898)"
      fill="#ff2410"
    />
    <path
      d="M508.17831,358.77776s25.54977,7.86147,20.63635,38.65222-2.948,52.40978-2.948,52.40978l32.75612,33.08367-11.79221,16.0505s-46.51368-14.08513-53.39246-34.72148S487.2144,362.05337,508.17831,358.77776Z"
      transform="translate(-148.32744 -147.38898)"
      opacity="0.1"
    />
    <path
      d="M506.54051,359.76045s25.54976,7.86146,20.63635,38.65221-2.94805,52.40978-2.94805,52.40978l32.75611,33.08368-11.7922,16.05049s-46.51368-14.08513-53.39247-34.72148S485.57659,363.03606,506.54051,359.76045Z"
      transform="translate(-148.32744 -147.38898)"
      opacity="0.1"
    />
    <path
      d="M507.19563,358.77776S532.7454,366.63923,527.832,397.43s-2.94805,52.40978-2.94805,52.40978L557.64,482.92343l-11.7922,16.0505s-46.51368-14.08513-53.39246-34.72148S486.23171,362.05337,507.19563,358.77776Z"
      transform="translate(-148.32744 -147.38898)"
      fill="#ff2410"
    />
    <path
      d="M537.495,319.63421l15.39538,14.41269a15.38424,15.38424,0,0,0-.68456,1.39557,26.55776,26.55776,0,0,1-6.84935.89736c-5.88011,0-10.997-2.98634-15.39537-6.22367C533.03553,327.33809,535.67273,324.64557,537.495,319.63421Z"
      transform="translate(-148.32744 -147.38898)"
      opacity="0.1"
    />
    <circle cx="393.09832" cy="148.98839" r="26.53245" fill="#e0e0e0" />
    <circle cx="397.5204" cy="161.27193" r="26.53245" fill="#fda57d" />
    <path
      d="M519.47917,296.37737s-9.93543,26.01575,11.08278,33.97179h0a12.43313,12.43313,0,0,0,3.10131-19.898Z"
      transform="translate(-148.32744 -147.38898)"
      fill="#e0e0e0"
    />
    <path
      d="M567.31527,290.55281s9.15954,2.22138-6.891,7.78992A20.30562,20.30562,0,0,0,548.397,309.19305a12.47944,12.47944,0,0,1-17.28769,5.70064l-5.0789-2.79339L517.18624,291.464l10.482-12.11976,18.01586-2.29293Z"
      transform="translate(-148.32744 -147.38898)"
      fill="#e0e0e0"
    />
    <ellipse
      cx="390.47783"
      cy="169.9523"
      rx="2.62049"
      ry="4.58586"
      fill="#fda57d"
    />
    <path
      d="M529.79735,338.79653S547.64943,366.803,557.80382,366.803s-7.12121-26.26137-7.12121-26.26137l-.41269,7.26282Z"
      transform="translate(-148.32744 -147.38898)"
      fill="#333"
    />
    <path
      d="M632.63085,583.58071c-59.327,0-120.77186-6.02788-183.68645-18.06007C279.746,533.16269,188.317,470.4022,183.31218,467.70642l-15.082-25.08191.00375.00214v-.00053c1.4119.75844,121.56189,64.66714,286.65211,96.11025C606.387,567.58921,828.06293,574.217,1023.78,441.92552l-4.30087,30.20882c-90.0497,60.86852-173.039,91.3263-285.43268,105.29744A822.184,822.184,0,0,1,632.63085,583.58071Z"
      transform="translate(-148.32744 -147.38898)"
      fill="#d80000"
    />
    <rect
      x="25.44281"
      y="274.79951"
      width="19.78885"
      height="279.87093"
      fill="#e0e0e0"
    />
    <path
      d="M18.98875,545.48276h32.697A18.98875,18.98875,0,0,1,70.67448,564.4715V580.82a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V564.4715A18.98875,18.98875,0,0,1,18.98875,545.48276Z"
      fill="#e0e0e0"
    />
    <circle cx="35.33724" cy="287.52092" r="19.78885" opacity="0.1" />
    <circle cx="35.33724" cy="286.10743" r="19.78885" fill="#e0e0e0" />
    <path
      d="M173.77026,694.27691s12.7214,4.234,19.78885.00093"
      transform="translate(-148.32744 -147.38898)"
      opacity="0.1"
    />
    <rect
      x="858.11345"
      y="274.79951"
      width="19.78885"
      height="279.87093"
      fill="#e0e0e0"
    />
    <path
      d="M851.65938,545.48276h32.697a18.98874,18.98874,0,0,1,18.98874,18.98874V580.82a0,0,0,0,1,0,0H832.67063a0,0,0,0,1,0,0V564.4715A18.98875,18.98875,0,0,1,851.65938,545.48276Z"
      fill="#e0e0e0"
    />
    <circle cx="868.00787" cy="287.52092" r="19.78885" opacity="0.1" />
    <circle cx="868.00787" cy="286.10743" r="19.78885" fill="#e0e0e0" />
    <path
      d="M1006.44089,694.27691s12.72141,4.234,19.78885.00093"
      transform="translate(-148.32744 -147.38898)"
      opacity="0.1"
    />
  </svg>
);

export default AttendeeImage;
