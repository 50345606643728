import React from 'react';
import PropTypes from 'prop-types';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import LogoIcon from '../../svg/LogoIcon';
import Button from '../Button';

function Header({ hide }) {
  if (hide) {
    return null;
  }

  return (
    <header className="sticky top-0 bg-white shadow" style={{ zIndex: 10 }}>
      <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
        <div className="flex items-center text-2xl">
          <div className="w-12 mr-3">
            <LogoIcon />
          </div>
          Eventful
        </div>
        <div className="flex mt-4 sm:mt-0 eventful-a text-lg">
          <AnchorLink className="px-4" href="#features" offset="-75">
            Features
          </AnchorLink>
          <AnchorLink className="px-4" href="#workflow" offset="20">
            Workflow
          </AnchorLink>
          <AnchorLink className="px-4" href="#screenshots" offset="-10">
            Screenshots
          </AnchorLink>
          <AnchorLink className="px-4" href="#contact" offset="50">
            Contact us
          </AnchorLink>
        </div>
        <div className="hidden md:block">
          <Button
            className="text-sm eventful-button text-lg"
            size="md"
            onClick={() => {
              window.location = 'https://plan.eventful-app.com';
            }}
          >
            Get Started
          </Button>
        </div>
      </div>
    </header>
  );
}

Header.propTypes = {
  hide: PropTypes.bool
};

Header.defaultProps = {
  hide: false
};

export default Header;
