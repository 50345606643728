import React from 'react';
import { Helmet } from 'react-helmet';
import { toast, Slide } from 'react-toastify';
import {
  FaRegStar,
  FaSearch,
  FaRegHandshake,
  FaChartPie,
  FaRegGrin,
  FaRocket,
  FaCheck,
  FaTimes,
  FaCrown,
  FaApple,
  FaGooglePlay
} from 'react-icons/fa';
import { MdPhonelink } from 'react-icons/md';
import { IconContext } from 'react-icons';
import Button from '../components/Button';
import Card from '../components/Card';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import ImageCarousel from '../components/ImageCarousel';
import ContactForm from '../components/ContactForm';
import HeroImage from '../svg/HeroImage';
import ExhibitorImage from '../svg/ExhibitorImage';
import OrganizerImage from '../svg/OrganizerImage';
import AttendeeImage from '../svg/AttendeeImage';
import AppPerspective from '../images/app_perspective.png';
import 'react-toastify/dist/ReactToastify.css';

toast.configure({
  transition: Slide
});

export default () => (
  <div>
    <Helmet>
      <title>Eventful, a SAAS platform for event management</title>
      <meta property="og:title" content="Eventful" />
      <meta
        property="og:description"
        content="Multi-platform service that makes Event Organization and information sharing easy."
      />
      <meta
        property="og:image"
        content="https://res.cloudinary.com/chocolat/image/upload/v1574837524/eventful_logo_fcrl8y.png"
      />
      <meta property="og:url" content="https://eventful-app.com/" />
      <meta
        name="description"
        content="Multi-platform service that makes Event Organization and information sharing easy."
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="robots" content="noodp,noydir" />
    </Helmet>

    <Layout>
      <section className="pt-20 lg:pt-40">
        <div className="container mx-auto px-8 lg:flex">
          <div className="text-center lg:text-left lg:w-1/2">
            <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">Eventful</h1>
            <p className="text-xl lg:text-2xl mt-6 font-light">
              Multi-platform service that makes Event Organization and information sharing easy.
            </p>
            <Button
              className="eventful-button mt-8 md:mt-12 text-lg"
              size="lg"
              onClick={() => {
                window.location = 'https://plan.eventful-app.com';
              }}
            >
              Get Started
            </Button>
            <p className="mt-4 text-gray-600">With our management dashboard</p>
          </div>
          <div className="lg:w-1/2">
            <HeroImage />
          </div>
        </div>
      </section>
      <IconContext.Provider
        value={{
          color: '#ee0202',
          size: '50',
          style: { display: 'inline-flex', paddingBottom: 10 }
        }}
      >
        <section id="features" className="py-20 mx-4 lg:pt-48">
          <div className="container mx-auto text-center">
            <h2 className="text-3xl lg:text-5xl font-semibold">Features</h2>
            <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
              <div className="flex-1 px-3">
                <Card className="mb-8 p-12 feature-card">
                  <FaRegStar />
                  <p className="font-semibold text-xl">Easy to Use</p>
                  <p className="mt-4">
                    Create an Event in just a few clicks and have your digital presence ready. Save
                    energy and time to invest it where it matters.
                  </p>
                </Card>
              </div>
              <div className="flex-1 px-3">
                <Card className="mb-8 p-12 feature-card">
                  <FaSearch />
                  <p className="font-semibold text-xl">Granular Control</p>
                  <p className="mt-4">
                    From the smaller details, to Exhibitors, Maps, Schedule, we have it covered. And
                    you have total control over what you show.
                  </p>
                </Card>
              </div>
              <div className="flex-1 px-3">
                <Card className="mb-8 p-12 feature-card">
                  <MdPhonelink />
                  <p className="font-semibold text-xl">Cross-Platform</p>
                  <p className="mt-4">
                    Your Event space is accessible from the Eventful app available on iOS and
                    Android, soon coming to the web!
                  </p>
                </Card>
              </div>
            </div>
            <div className="flex flex-col sm:flex-row sm:-mx-3">
              <div className="flex-1 px-3">
                <Card className="mb-8 p-12 feature-card">
                  <FaRegHandshake />
                  <p className="font-semibold text-xl">Distribution of Work</p>
                  <p className="mt-4">
                    Exhibitors will take care of building their own profile, saving you time and
                    headache of regrouping all the information.
                  </p>
                </Card>
              </div>
              <div className="flex-1 px-3">
                <Card className="mb-8 p-12 feature-card">
                  <FaChartPie />
                  <p className="font-semibold text-xl">Track Everything</p>
                  <p className="mt-4">
                    From the Event itself, to Exhibitors and everything that&apos;s happening,
                    you&apos;ll know what your Attendees are most interested in.
                  </p>
                </Card>
              </div>
              <div className="flex-1 px-3">
                <Card className="mb-8 p-12 feature-card">
                  <FaRegGrin />
                  <p className="font-semibold text-xl">Free</p>
                  <p className="mt-4">
                    The basic tier of our service will always be Free, because big or small, your
                    Event matters.
                  </p>
                </Card>
              </div>
            </div>
          </div>
        </section>
      </IconContext.Provider>
      <section id="workflow" className="lg:pt-24">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl lg:text-5xl font-semibold">How does it work</h2>
        </div>
        <SplitSection
          primarySlot={
            <div className="lg:pr-32">
              <h3 className="text-3xl font-semibold leading-tight">Organizers</h3>
              <p className="mt-8 text-xl font-light leading-relaxed">
                Organizers can create Events from the management dashboard, where you can customize
                the information displayed. Interactive Maps, Schedule, News posts, etc. Moreso, you
                can invite Exhibitors to join your Event by email, and they will manage their
                profile. Once done, your Event space will appear on the Eventful app.
              </p>
            </div>
          }
          secondarySlot={<OrganizerImage />}
        />
        <SplitSection
          reverseOrder
          primarySlot={
            <div className="lg:pl-32">
              <h3 className="text-3xl font-semibold leading-tight">Exhibitors</h3>
              <p className="mt-8 text-xl font-light leading-relaxed">
                Exhibitors can setup their profile from the Eventful app under the My Exhibitor
                Profiles section. Once invited to an Event, you will receive a notification, to
                accept to join. From then on, you can modify your profile at any moment, and il will
                reflect on the Event in real time.
              </p>
            </div>
          }
          secondarySlot={<ExhibitorImage />}
        />
        <SplitSection
          primarySlot={
            <div className="lg:pr-32">
              <h3 className="text-3xl font-semibold leading-tight">Attendees</h3>
              <p className="mt-8 text-xl font-light leading-relaxed">
                Attendees can access the Event space once made public by the Organizer, from the
                Eventful app, available on iOS and Android. There, they can access all the related
                information, plus many added functionalities, such as Notes, Sharing, Likes, etc.
              </p>
            </div>
          }
          secondarySlot={<AttendeeImage />}
        />
      </section>
      <ImageCarousel id="screenshots" />
      <section id="pricing" className="py-12 mx-auto lg:py-20">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl lg:text-5xl font-semibold">Our Pricing</h2>
          <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
            <div className="flex-1 lg:px-3 sm:-mx-3 flex-end" style={{ display: 'inline-flex' }}>
              <Card className="price-card mb-8 mx-auto lg:mx-6 p-12 lg:px-24">
                <IconContext.Provider
                  value={{
                    color: '#ee0202',
                    size: '60',
                    style: { display: 'inline-flex', paddingBottom: 10 }
                  }}
                >
                  <FaRocket />
                </IconContext.Provider>
                <p className="font-semibold text-3xl">Free</p>
                <p className="mt-4 mb-4 text-xl text-left" style={{ lineHeight: 1.9 }}>
                  <IconContext.Provider
                    value={{
                      color: '#00c700',
                      size: '30',
                      style: { display: 'inline-flex', paddingBottom: 5 }
                    }}
                  >
                    <FaCheck />
                    &nbsp;Unlimited Events
                    <br />
                    <FaCheck />
                    &nbsp;Unlimited Exhibitors
                    <br />
                    <FaCheck />
                    &nbsp;Unlimited Attendees
                    <br />
                    <FaCheck />
                    &nbsp;Email Support
                  </IconContext.Provider>
                  <IconContext.Provider
                    value={{
                      color: 'grey',
                      size: '30',
                      style: { display: 'inline-flex', paddingBottom: 5 }
                    }}
                  >
                    <br />
                    <FaTimes />
                    &nbsp;Live Statistics
                    <br />
                    <FaTimes />
                    &nbsp;Ad-Free
                  </IconContext.Provider>
                </p>
                <div className="price">
                  $0
                  <span>/mo</span>
                </div>
                <Button
                  className="eventful-button"
                  size="sm"
                  onClick={() => {
                    window.location = 'https://plan.eventful-app.com';
                  }}
                >
                  Get Started
                </Button>
              </Card>
            </div>
            <div className="flex-1 lg:px-3 sm:-mx-3 flex-start" style={{ display: 'inline-flex' }}>
              <Card className="price-card mb-8 mx-auto lg:mx-6 p-12 lg:px-24">
                <IconContext.Provider
                  value={{
                    color: '#ee0202',
                    size: '60',
                    style: { display: 'inline-flex', paddingBottom: 10 }
                  }}
                >
                  <FaCrown />
                </IconContext.Provider>
                <p className="font-semibold text-3xl">Premium</p>
                <p className="mt-4 mb-4 text-xl text-left" style={{ lineHeight: 1.9 }}>
                  <IconContext.Provider
                    value={{
                      color: '#00c700',
                      size: '30',
                      style: { display: 'inline-flex', paddingBottom: 5 }
                    }}
                  >
                    <FaCheck />
                    &nbsp;Unlimited Events
                    <br />
                    <FaCheck />
                    &nbsp;Unlimited Exhibitors
                    <br />
                    <FaCheck />
                    &nbsp;Unlimited Attendees
                    <br />
                    <FaCheck />
                    &nbsp;Priority Support
                    <br />
                    <FaCheck />
                    &nbsp;Live Statistics
                    <br />
                    <FaCheck />
                    &nbsp;Ad-Free
                  </IconContext.Provider>
                </p>
                <div className="price invisible">
                  $-
                  <span>/mo</span>
                </div>
                <Button className="disabled-button" size="sm">
                  Coming soon
                </Button>
              </Card>
            </div>
          </div>
        </div>
      </section>
      <section id="team" className="py-8 mx-4 lg:py-20">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl lg:text-5xl font-semibold">The Team</h2>
          <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
            <div className="flex-1 px-3">
              <Card className="mb-8 team-card">
                <img
                  src="https://res.cloudinary.com/chocolat/image/upload/v1584845774/received_493914781330647_f9818g.jpg"
                  alt="Gabriel Bottari"
                />
                <p className="mt-4 font-semibold text-xl">Gabriel Bottari</p>
                <p>Founder &amp; CEO</p>
                <p className="my-6 mx-6">
                  Challenge driven Software Engineer, who previously worked at Rakuten, Microsoft
                  and Google.
                </p>
              </Card>
            </div>
            <div className="flex-1 px-3">
              <Card className="mb-8 team-card">
                <img
                  src="https://res.cloudinary.com/chocolat/image/upload/v1584846098/DSC_3418_afwpyk.jpg"
                  alt="Philippe Le"
                />
                <p className="mt-4 font-semibold text-xl">Philippe Le</p>
                <p>UI/UX Strategist</p>
                <p className="my-6 mx-6">
                  Software Engineer on a quest to make the world a better place by connecting people
                  in new innovative ways.
                </p>
              </Card>
            </div>
            <div className="flex-1 px-3">
              <Card className="mb-8 team-card">
                <img
                  src="https://res.cloudinary.com/chocolat/image/upload/v1584846644/eddie_nxsltw.jpg"
                  alt="Eddie Zhou"
                />
                <p className="mt-4 font-semibold text-xl">Eddie Zhou</p>
                <p>Back-End &amp; DevOps Authority</p>
                <p className="my-6 mx-6">
                  UBC Computer Science Alumni and active member of the Greater Vancouver Region
                  anime community.
                </p>
              </Card>
            </div>
          </div>
        </div>
      </section>
      <section id="download-app">
        <SplitSection
          primarySlot={
            <div className="lg:pr-16">
              <h3 className="text-3xl font-semibold leading-tight">Download Eventful</h3>
              <p className="mt-8 text-xl font-light leading-relaxed">
                Attendees &amp; Exhibitors can download the Eventful app to access all of your Event
                information in one place. This is where the magic happens.
              </p>
              <IconContext.Provider
                value={{
                  size: '25',
                  style: { display: 'inline-flex', paddingBottom: 5 }
                }}
              >
                <Button
                  className="eventful-button mt-8 mr-4"
                  size="md"
                  onClick={() => window.open('https://apps.apple.com/us/app/eventful/id1475728805')}
                >
                  <FaApple />
                  &nbsp;App Store
                </Button>
                <Button
                  className="eventful-button mt-4"
                  size="md"
                  onClick={() =>
                    window.open(
                      'https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=chaud.chocolat.eventful'
                    )
                  }
                >
                  <FaGooglePlay />
                  &nbsp;Play Store
                </Button>
              </IconContext.Provider>
            </div>
          }
          secondarySlot={<img src={AppPerspective} alt="Eventful App" />}
        />
      </section>
      <ContactForm />
    </Layout>
  </div>
);
