import React from 'react';
import TextField from '@material-ui/core/TextField';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import * as EmailValidator from 'email-validator';
import Button from './Button';
import getPageContext from '../utils/getPageContext';
import '../css/styles.css';

const StyledInput = styled(TextField)`
  width: 80%;
  max-width: 500px;
  & .MuiOutlinedInput-root:hover fieldset {
    border-color: #d32f2f !important;
  }
  & .MuiFormLabel-root.Mui-focused {
    color: #d32f2f !important;
  }
  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #d32f2f !important;
  }
`;

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      nameError: false,
      email: '',
      emailError: false,
      message: '',
      messageError: false
    };

    this.muiPageContext = getPageContext();
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    const { target } = event;
    const { name } = target;

    switch (name) {
      case 'name':
        this.setState({ nameError: false });
        break;
      case 'email':
        this.setState({ emailError: false });
        break;
      case 'message':
        this.setState({ messageError: false });
        break;
      default:
        break;
    }

    this.setState({
      [name]: target.value
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    const { name, email, message } = this.state;
    const isValidEmail = EmailValidator.validate(email);
    if (name === '' || email === '' || !isValidEmail || message === '') {
      if (name === '') {
        this.setState({ nameError: true });
      }

      if (email === '' || !isValidEmail) {
        this.setState({ emailError: true });
      }

      if (message === '') {
        this.setState({ messageError: true });
      }

      return;
    }

    fetch('https://qa.eventful-app.com/api/v1/contact?type=website_contact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ name, email, message })
    }).then(response => {
      if (response.status === 204) {
        toast.success("Message sent successfully! We'll be in contact soon.");
        this.setState({ name: '', email: '', message: '' });
      } else {
        toast.error('Something went wrong..');
      }
    });
  }

  render() {
    const { name, nameError, email, emailError, message, messageError } = this.state;
    return (
      <section
        id="contact"
        className="container mx-auto mt-20 py-20 bg-gray-200 text-center full-width"
      >
        <h3 className="text-5xl font-semibold">Get in Contact</h3>
        <p className="my-4 mx-6 text-xl font-light">
          Don&apos;t hesistate to use the form below to get in contact with us.
        </p>
        <form autoComplete="off" onSubmit={this.handleSubmit}>
          <div>
            <StyledInput
              name="name"
              label="Name"
              margin="normal"
              variant="outlined"
              value={name}
              onChange={this.handleInputChange}
              error={nameError}
              helperText={nameError ? 'Cannot be empty' : null}
            />
          </div>
          <div>
            <StyledInput
              name="email"
              label="Email"
              margin="normal"
              variant="outlined"
              value={email}
              onChange={this.handleInputChange}
              error={emailError}
              helperText={
                !emailError ? null : email === '' ? 'Cannot be empty' : 'Invalid email address'
              }
            />
          </div>
          <div>
            <StyledInput
              name="message"
              label="Message"
              multiline
              rows="4"
              margin="normal"
              variant="outlined"
              value={message}
              onChange={this.handleInputChange}
              error={messageError}
              helperText={messageError ? 'Cannot be empty' : null}
            />
          </div>
          <Button className="eventful-button mt-6 text-lg" size="md" type="submit">
            Send Message
          </Button>
        </form>
      </section>
    );
  }
}

export default ContactForm;
